import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import formatCardNum from 'simply-utils/number/formatCardNum';
import formatCardExp from 'simply-utils/number/formatCardExp';
import formatCvc from 'simply-utils/number/formatCvc';

import creditCardLogoSet from 'assets/images/credit-card-logos-set.png';

import InputTextField from 'components/InputTextField/InputTextField';
import VisitorLayout from 'layout/VisitorLayout/VisitorLayout';
import VisitorJumbotron from 'components/VisitorJumbotron/VisitorJumbotron';
import i18nNamespaces from 'i18n/i18nNamespaces';
import getAPIErrorI18nKey from 'utils/getAPIErrorI18nKey';
import CTAButton from 'components/CTAButton/CTAButton';
import FormSection from 'components/FormSection/FormSection';

import './CreditCardPaymentForm.scss';
import useBeforeUnloadBrowserAlert from 'hooks/useBeforeUnloadBrowserAlert';

interface FormData {
  accountNo: string;
  firstName: string;
  lastName: string;
  email: string;

  amount: number;

  cardNumber: string;
  cardExp: string;
  cardCvc: string;
  billingAddress: string;
  billingZipCode: string;
}

const CreditCardPaymentForm: FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation(i18nNamespaces.CREDIT_CARD_PAYMENT);
  const { t: tApp } = useTranslation(i18nNamespaces.APP);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useBeforeUnloadBrowserAlert();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    console.log('🚀 ~ file: CreditCardPaymentForm.tsx ~ line 52 ~ onSubmit ~ data', data);
    setSubmitError(null);
    setLoading(true);
    try {
      // @TODO: Integrate with API
      // const res = await postStripePayment(data);
      const res = await new Promise<{ status: 'ok' | 'error'; error?: string }>((resolve) => {
        setTimeout(() => {
          resolve({ status: 'ok', error: '' });
        }, 3000);
      });
      switch (res.status) {
        case 'ok': {
          setTimeout(() => history.push('/payment/credit-card/success'), 0);
          break;
        }
        case 'error':
          setSubmitError(res.error ? t(`error_${res.error}`) : null);
          break;
        default:
          break;
      }
    } catch (err: any) {
      // @TODO: Log error message to server
      setSubmitError(t(getAPIErrorI18nKey(err, i18nNamespaces.CREDIT_CARD_PAYMENT)));
    } finally {
      setLoading(false);
    }
  };

  return (
    <VisitorLayout className="CreditCardPaymentForm" hasError={!!submitError}>
      {({ formClassName, formContainerClassName, formButtonsContainerClassName }) => (
        <>
          <VisitorJumbotron title={tApp('title')} description={t('title')} />
          <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <div className={formContainerClassName}>
              <FormSection title={t('section_patient')}>
                <InputTextField
                  {...register('firstName', { required: true })}
                  name="firstName"
                  label={t('firstName')}
                  placeholder={t('firstName_placeholder')}
                  disabled={loading}
                />
                <InputTextField
                  {...register('lastName', { required: true })}
                  name="lastName"
                  label={t('lastName')}
                  placeholder={t('lastName_placeholder')}
                  disabled={loading}
                />
                <InputTextField
                  {...register('email', { required: true })}
                  name="email"
                  label={t('email')}
                  type="email"
                  placeholder={t('email_placeholder')}
                  disabled={loading}
                />
                <InputTextField
                  {...register('accountNo', { required: true })}
                  name="accountNo"
                  label={t('accountNo')}
                  placeholder={t('accountNo_placeholder')}
                  disabled={loading}
                />
              </FormSection>
              <FormSection title={t('section_amount')}>
                <InputTextField
                  {...register('amount', { required: true })}
                  name="amount"
                  placeholder={t('amount_placeholder')}
                  type="number"
                  min={0.01}
                  max={9999999999}
                  step="0.01"
                  className="common-form-field-amount"
                  elementAfter={<div className="common-form-field-amount-prefix">USD $</div>}
                  disabled={loading}
                />
              </FormSection>
              <FormSection title={t('section_card_info')}>
                <InputTextField
                  {...register('cardNumber', { required: true })}
                  name="cardNumber"
                  label={t('cardNumber')}
                  placeholder="e.g. 4242 4242 4242 4242"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('cardNumber', formatCardNum(e.target.value));
                  }}
                  onBlur={(e) => setValue('cardNumber', formatCardNum(e.target.value))}
                  disabled={loading}
                />
                <InputTextField
                  {...register('cardExp', { required: true })}
                  name="cardExp"
                  label={t('cardExp')}
                  placeholder="MM/YY (e.g. 12/26)"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('cardExp', formatCardExp(e.target.value));
                  }}
                  onBlur={(e) => setValue('cardExp', formatCardExp(e.target.value))}
                  disabled={loading}
                />
                <InputTextField
                  {...register('cardCvc', { required: true })}
                  name="cardCvc"
                  label={t('cardCvc')}
                  placeholder="e.g. 123"
                  min={3}
                  max={3}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('cardCvc', formatCvc(e.target.value));
                  }}
                  onBlur={(e) => setValue('cardCvc', formatCvc(e.target.value))}
                  disabled={loading}
                />
                <InputTextField
                  {...register('billingAddress', { required: true })}
                  name="billingAddress"
                  label={t('billingAddress')}
                  placeholder="e.g. 123 ABC Street, NY"
                  disabled={loading}
                />
                <InputTextField
                  {...register('billingZipCode', { required: true })}
                  name="billingZipCode"
                  label={t('billingZipCode')}
                  placeholder="e.g. 10001"
                  disabled={loading}
                />
              </FormSection>
            </div>
            <img
              src={creditCardLogoSet}
              alt="Accepted Credit Cards"
              className="CreditCardPaymentForm-credit-card-logo-set"
            />
            <div className={formButtonsContainerClassName}>
              <CTAButton type="submit" text={loading ? t('ctaLoading') : t('cta')} disabled={loading} />
            </div>
          </form>
          <br />
        </>
      )}
    </VisitorLayout>
  );
};

export default CreditCardPaymentForm;
