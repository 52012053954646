import { useEffect } from 'react';

const useBeforeUnloadBrowserAlert = (message = 'Are you sure you want to leave') => {
  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (e.returnValue = message);
    };
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  });
};

export default useBeforeUnloadBrowserAlert;
